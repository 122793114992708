/* eslint-disable max-len */
import { decimalToFraction } from '@gcc/autobahn-utilities';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { bmsm } from '../../badges/bmsm';
import {
  BadgePriceSuffix,
  BMSMIcon,
  Cents,
  Currency,
  CurrencyAndPrice,
  Dollars,
  PromoMessage,
  PriceAndPromoWrapper,
  Suffix,
  StyledPrice
} from './product-price.styles';

const ProductPrice = observer(({ vm, showSuffix = false }) => {
  const {
    price: { badge, originalPrice = 0, promo, isBMSMPromoType },
  } = vm.productPriceViewModel;

  useEffect(() => {
    if (originalPrice !== 0) {
      const loading = document.querySelector('#thdpip-loading');
      if (loading) {
        loading.style.display = 'none';
      }
    }
  }, [originalPrice]);

  const showSuffixText = showSuffix
    ? `for
      ${vm.width.wholes}
      ${decimalToFraction(vm.width.eighths)}
      in by
      ${vm.height.wholes}
      ${decimalToFraction(vm.height.eighths)}
      in`
    : undefined;
  return (
    <>
      {isBMSMPromoType && (
        <>
          <PriceAndPromoWrapper>
            <BadgePriceSuffix>
              <BMSMIcon src={bmsm} />
              <CurrencyAndPrice>
                <Currency>$</Currency>
                <Dollars>52</Dollars>
                <Cents>99</Cents>
              </CurrencyAndPrice>
              <Suffix>{showSuffixText}</Suffix>
            </BadgePriceSuffix>
            <PromoMessage>{promo.productDescription}</PromoMessage>
          </PriceAndPromoWrapper>
        </>
      )}
      {!isBMSMPromoType && <StyledPrice badge={badge} price={originalPrice} promo={promo} suffix={showSuffixText} />}
    </>
  );
});

export default ProductPrice;
