/* eslint-disable @mizdra/layout-shift/require-size-attributes */
import { useBreakpoint, Visualizer } from '@gcc/autobahn-components';
import { observer } from 'mobx-react-lite';
import React from 'react';

// eslint-disable-next-line import/no-useless-path-segments
import ProductPrice from '../../../product-price/product-price.view';
import {
  StyledCustomizeAndBuyButton,
  StyledPrice,
  StyledInfo,
  StyledNewTag,
  StyledNewTagWrapper,
  StyledProductPreviewTitle,
  StyledRatioBox,
  StyledSwatchName,
  StyledThumbnail,
  StyledThumbnailPrice,
  StyledVisualizerContainer,
  StyledMainContainer
} from './product-preview.styles';

import routeToLocation from '../../../../utils/route-to-location';

const ProductPreview = observer(({ vm }) => {
  const {
    ctaButtonLabel,
    configuratorUrl,
    product: { ProductId: productId, ProductName: productName, SiteId: siteId },
    productOptionsViewModel: { swatchSelections },
    productPreviewViewModel: {
      swatchName, swatchUrl, isEnhancedVisualization, updateForVisualization,
      visualizedOptions, isSwatchSelected, primarySwatch
    },
    baseUrl
  } = vm;

  const { isSmall } = useBreakpoint();

  return (
    <>
      {!isSmall && <StyledProductPreviewTitle>{productName}</StyledProductPreviewTitle>}
      {isEnhancedVisualization && !isSmall && (
        <StyledNewTagWrapper>
          <StyledNewTag>New</StyledNewTag>
          Enhanced Product Preview
        </StyledNewTagWrapper>
      )}
      <StyledMainContainer>
        { ((!isEnhancedVisualization || !isSmall || isSwatchSelected)) && siteId && (
          <>
            <StyledVisualizerContainer hasPrimarySwatch={!!primarySwatch} isSwatchSelected={isSwatchSelected}>
              <Visualizer
                options={visualizedOptions}
                imageAlternateText="Product Preview"
                productId={productId}
                siteId={siteId}
                minimizeEnabled={isSmall}
                allowZoomActions={!isSmall}
                allowMinimizationActions={false}
                visualizationApiUrl={`${baseUrl}/api/visualization/url`}
                apiCallback={updateForVisualization}
              />
            </StyledVisualizerContainer>
            <StyledInfo>
              <StyledThumbnailPrice>
                <StyledThumbnail>
                  <StyledRatioBox width="1" height="1" image={swatchUrl} />
                  <StyledSwatchName lang="de">{swatchName}</StyledSwatchName>
                </StyledThumbnail>
                <StyledPrice>
                  <ProductPrice vm={vm} showSuffix />
                </StyledPrice>
              </StyledThumbnailPrice>
              <StyledCustomizeAndBuyButton
                label={ctaButtonLabel}
                color="primary"
                // eslint-disable-next-line no-return-assign
                onClick={() => routeToLocation(configuratorUrl)}
              />
            </StyledInfo>
          </>
        )}
      </StyledMainContainer>

    </>
  );
});

export default ProductPreview;
