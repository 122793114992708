/* eslint-disable no-console */
import { publish } from '@gcc/autobahn-utilities';
import currency from 'currency.js';
import { each, find } from 'lodash';
import {
  action, computed, makeObservable, observable, runInAction
} from 'mobx';

import ProductService from '../../api/product.service';

class ProductPriceViewModel {
  constructor(rootVm) {
    makeObservable(this, {
      discountPrice: computed,
      getProductPrice: action.bound,
      originalPrice: observable,
      percentOff: observable,
      price: observable,
      pricingContext: computed,
      rootVm: false,
    });
    this.rootVm = rootVm;
    this.price = {};
  }

  originalPrice = undefined;

  price = {};

  percentOff = undefined;

  setPrice(price) {
    this.price = price;
  }

  get discountPrice() {
    const percentOff = this.percentOff && this.percentOff > 0 && this.percentOff / 100;
    const discount = percentOff && currency(this.originalPrice).multiply(percentOff).value;
    return discount ? currency(this.originalPrice).subtract(discount).value : this.originalPrice;
  }

  getProductPrice() {
    const selectedPricingContext = JSON.stringify(this.pricingContext);
    const $this = this;
    const params = {
      height: this.rootVm.dimensions.float.height,
      selectedPricingContext,
      width: this.rootVm.dimensions.float.width,
    };
    ProductService.getProductPrice(params)
      .then((res) => {
        runInAction(async () => {
          const { Data } = res.data;
          const { ColorChoicePrices: swatchesPrices, LowestPrice, PromoPriceModel, IsBMSMPromoType } = Data;
          let originalPrice;
          let percentOff;
          let productDescription;
          if (PromoPriceModel) {
            productDescription = PromoPriceModel.productDescription;
            originalPrice = PromoPriceModel.originalPrice;
            percentOff = PromoPriceModel.percentOff;
          } else {
            originalPrice = LowestPrice;
          }
          $this.originalPrice = originalPrice;
          $this.percentOff = percentOff;
          $this.rootVm.productOptionsViewModel.setColorSwatchesSections(swatchesPrices, percentOff);
          $this.setPrice({
            badge: percentOff ? 'special-buy' : undefined,
            originalPrice: +originalPrice,
            promo: { percentOff: !!percentOff && percentOff > 0 ? percentOff : undefined, productDescription },
            isBMSMPromoType: IsBMSMPromoType,
          });
          publish($this.rootVm.productPreviewEventName, { price: { originalPrice, percentOff, productDescription } });
          // sending to event Bus to change Price Harmony component
          const propProductData = {
            configuredPrice: {
              pricing: {
                alternate: {
                  bulk: {
                    value: null,
                    thresholdQuantity: null,
                  },
                  unit: {
                    unitsPerCase: null,
                    caseUnitOfMeasure: null,
                    unitsOriginalPrice: null,
                    value: null,
                  }
                },
                alternatePriceDisplay: false,
                mapAboveOriginalPrice: null,
                message: null,
                original: +this.originalPrice,
                preferredPriceFlag: null,
                promotion: {
                  dates: {
                    start: null,
                    end: null,
                  },
                  description: {
                    longDesc: null,
                    shortDesc: productDescription,
                  },
                  dollarOff: this.originalPrice - this.discountPrice,
                  percentageOff: this.percentOff,
                  savingsCenter: this.percentOff > 0 ? 'Special Buys' : undefined,
                  savingsCenterPromos: null,
                  specialBuySavings: null,
                  specialBuyDollarOff: null,
                  specialBuyPercentageOff: null,
                  type: 'DISCOUNT',
                },
                specialBuy: null,
                unitOfMeasure: 'each',
                value: this.discountPrice,
              }
            }
          };
          LIFE_CYCLE_EVENT_BUS.trigger('configurator.configurator_pricing_event', propProductData);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  get pricingContext() {
    const { localStorage } = window;
    const { product } = this.rootVm;
    const savedState = localStorage.getItem(`option-state-${this.rootVm.product.ProductId}`);
    const selectedChoiceIds = [];
    const widthOption = find(product.DimensionsChoicesInfo, ['OptionType', 'Width']);
    const heightOption = find(product.DimensionsChoicesInfo, ['OptionType', 'Height']);

    if (!widthOption || !heightOption) {
      return undefined;
    }

    if (savedState) {
      const selectedChoices = JSON.parse(savedState);
      each(selectedChoices, (sc) => {
        // Add to the collection only the sampleable choices
        if (sc.id !== widthOption.ChoiceId && sc.id !== heightOption.ChoiceId) {
          selectedChoiceIds.push(sc.id);
        }
      });
    }

    const params = {
      Choices: [],
      ForContextOnly: true,
      ProductId: product.ProductId,
    };

    if (widthOption) {
      params.Choices.push({
        ChoiceId: widthOption.ChoiceId,
        ChoiceValue: parseFloat(`${this.rootVm.width.wholes}.${this.rootVm.width.eighths}`),
        OptionId: widthOption.OptionId,
        OptionType: 'width',
      });
    }

    if (heightOption) {
      params.Choices.push({
        ChoiceId: heightOption.ChoiceId,
        ChoiceValue: parseFloat(`${this.rootVm.height.wholes}.${this.rootVm.height.eighths}`),
        OptionId: heightOption.OptionId,
        OptionType: 'height',
      });
    }

    // Samples (mostly colors)
    if (selectedChoiceIds && selectedChoiceIds.length > 0) {
      each(this.rootVm.product.ColorGroups, (sampleOption) => {
        each(sampleOption.Swatches, (choice) => {
          if (selectedChoiceIds.includes(choice.ChoiceId)) {
            params.Choices.push({
              ChoiceId: choice.ChoiceId,
              OptionId: choice.OptionId,
              OptionType: '',
              ChoiceValue: true,
            });
            const index = selectedChoiceIds.indexOf(choice.ChoiceId);
            if (index > -1) selectedChoiceIds.splice(index, 1);
          }
          if (!selectedChoiceIds.length) {
            return params;
          }
          return true;
        });
      });
    }
    return params;
  }

  rootVm;
}

export default ProductPriceViewModel;
