/* eslint-disable max-len */
export const bmsm = `<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2849_11143)">
<g clip-path="url(#clip1_2849_11143)">
<path d="M37.0197 35.7083L20.3316 39.5754L3.75833 35.7083L0 0H41L37.0197 35.7083Z" fill="#454547"/>
<path d="M8.29683 18.6659V19.0174C8.29683 19.7762 8.50784 20.353 9.32237 20.353C10.1522 20.353 10.3906 19.7347 10.3906 19.1579C10.3906 16.9937 6.07681 18.2024 6.07681 14.45C6.07681 12.8478 6.93344 11.498 9.36501 11.498C11.7261 11.498 12.569 12.692 12.569 14.2379V14.4768H10.2922C10.3209 14.1143 10.2532 13.7507 10.0959 13.4229C10.0222 13.3023 9.9158 13.2051 9.78899 13.1428C9.66218 13.0805 9.52025 13.0555 9.37977 13.0708C8.78883 13.0708 8.43787 13.4229 8.43787 14.1532C8.43787 16.2469 12.7527 15.1919 12.7527 18.7343C12.7527 21.0111 11.4877 21.9246 9.32292 21.9246C7.60912 21.9246 6.02051 21.4047 6.02051 19.311V18.6659H8.29683Z" fill="white"/>
<path d="M15.7487 11.6943H18.7007L21.2023 21.729H18.7587L18.3929 19.8156H15.9876L15.6361 21.729H13.1914L15.7487 11.6943ZM17.1826 13.5218H17.1542L16.326 17.9908H18.0398L17.1826 13.5218Z" fill="white"/>
<path d="M20.377 11.6943H22.9069L24.1014 19.4526H24.1298L25.3385 11.6943H27.8696L25.5916 21.729H22.5849L20.377 11.6943Z" fill="white"/>
<path d="M28.6309 11.6943H34.4359V13.6077H30.9919V15.6167H34.2238V17.53H30.9925V19.82H34.5901V21.7333H28.6314L28.6309 11.6943Z" fill="white"/>
<path d="M6.01562 24.1777H9.03377L10.114 29.9325H10.1402L11.1789 24.1777H14.161V32.9392H12.2476V26.1168H12.2236L10.8859 32.9392H9.2918L7.88085 26.1168H7.85625V32.9392H6.01562V24.1777Z" fill="white"/>
<path d="M18.3895 33.111C15.678 33.111 15.3096 31.3442 15.3096 28.5589C15.3096 25.7737 15.678 24.0068 18.3895 24.0068C21.3103 24.0068 21.47 26.1295 21.47 28.5589C21.47 30.9883 21.3103 33.111 18.3895 33.111ZM18.3747 31.7367C19.2089 31.7367 19.3325 30.9632 19.3325 28.5584C19.3325 26.1536 19.2095 25.3801 18.3747 25.3801C17.5651 25.3801 17.4427 26.1536 17.4427 28.5584C17.4427 30.9632 17.5673 31.7367 18.3747 31.7367Z" fill="white"/>
<path d="M22.5527 24.1777H26.0126C27.7668 24.1777 28.3687 25.2574 28.3687 26.4601C28.3687 27.736 27.8532 28.3619 26.8955 28.5953V28.6194C28.2075 28.8036 28.2829 29.6258 28.3069 30.8061C28.3436 32.5357 28.4053 32.7079 28.6995 32.8287V32.9381H26.4789C26.2753 32.3684 26.2006 31.7607 26.2602 31.1587C26.2602 29.6373 26.0519 29.318 25.4014 29.318H24.6142V32.9381H22.5527V24.1777ZM24.6142 27.8716H25.2522C25.4002 27.8789 25.5481 27.8537 25.6853 27.7977C25.8226 27.7417 25.9459 27.6563 26.0465 27.5474C26.1471 27.4386 26.2226 27.309 26.2677 27.1677C26.3127 27.0265 26.3262 26.8771 26.3072 26.7301C26.3072 26.0681 26.0618 25.6264 25.2396 25.6264H24.6142V27.8716Z" fill="white"/>
<path d="M29.5996 24.1777H34.6678V25.8467H31.6611V27.6015H34.4835V29.2705H31.6611V31.2702H34.8022V32.9397H29.5996V24.1777Z" fill="white"/>
<path d="M39.9821 9.12933H0.958853L0 0H41L39.9821 9.12933Z" fill="#F96302"/>
<path d="M3.49707 1.93359H5.58479C6.56114 1.93359 7.20894 2.38951 7.20894 3.34235C7.23713 3.62857 7.16242 3.91547 6.99823 4.1516C6.83403 4.38773 6.59108 4.55763 6.31295 4.63085V4.64615C6.73716 4.71066 7.36966 4.89434 7.36966 6.06257C7.36966 6.73442 7.07391 7.6479 5.48147 7.6479H3.49707V1.93359ZM4.84132 4.21374H5.20978C5.2999 4.22446 5.39128 4.21432 5.47686 4.1841C5.56243 4.15388 5.63992 4.10439 5.70332 4.03945C5.76673 3.97452 5.81435 3.89587 5.84252 3.8096C5.87069 3.72332 5.87865 3.63173 5.86578 3.54189C5.8713 3.45672 5.85805 3.37137 5.82697 3.29188C5.79588 3.2124 5.74771 3.14071 5.68586 3.08189C5.62402 3.02307 5.55001 2.97855 5.46906 2.95148C5.38812 2.92442 5.30222 2.91546 5.21743 2.92525H4.84132V4.21374ZM4.84132 6.65406H5.24968C5.34926 6.66449 5.4499 6.65228 5.54408 6.61834C5.63827 6.5844 5.72357 6.5296 5.7936 6.45805C5.86362 6.3865 5.91657 6.30005 5.94847 6.20515C5.98038 6.11025 5.99042 6.00937 5.97784 5.91005C5.97784 5.34971 5.68975 5.11027 5.27374 5.11027H4.84132V6.65406Z" fill="white"/>
<path d="M11.7805 5.76026C11.7805 6.88038 11.4601 7.76051 9.90049 7.76051C8.34085 7.76051 8.02051 6.88038 8.02051 5.76026V1.93359H9.36476V6.15058C9.36476 6.5983 9.54079 6.86125 9.90104 6.86125C10.2613 6.86125 10.4373 6.59666 10.4373 6.15058V1.93359H11.7816L11.7805 5.76026Z" fill="white"/>
<path d="M13.5985 5.39837L12.1504 1.93414H13.6384L14.278 4.05411H14.2939L14.9499 1.93359H16.3903L14.9422 5.39837V7.64681H13.5985V5.39837Z" fill="white"/>
<path d="M18.4837 1.93359H20.4517L21.1553 5.68646H21.1712L21.8518 1.93359H23.7957V7.64626H22.5482V3.19749H22.5324L21.6577 7.64626H20.619L19.7001 3.19749H19.6831V7.64626H18.4805L18.4837 1.93359Z" fill="white"/>
<path d="M26.5509 7.75852C24.783 7.75852 24.543 6.60615 24.543 4.79012C24.543 2.97409 24.783 1.82227 26.5509 1.82227C28.4555 1.82227 28.5593 3.20643 28.5593 4.79012C28.5593 6.37381 28.4566 7.75852 26.5509 7.75852ZM26.5432 6.86253C27.0866 6.86253 27.167 6.35796 27.167 4.79012C27.167 3.22228 27.0872 2.71825 26.5432 2.71825C26.0151 2.71825 25.9353 3.22283 25.9353 4.79012C25.9353 6.35741 26.0168 6.86253 26.5449 6.86253H26.5432Z" fill="white"/>
<path d="M29.2666 1.93372H31.5227C31.7265 1.9079 31.9335 1.92822 32.1283 1.99317C32.3232 2.05813 32.501 2.16605 32.6485 2.30897C32.7961 2.45189 32.9096 2.62615 32.9807 2.81886C33.0518 3.01157 33.0787 3.21779 33.0594 3.42229C33.0594 4.25377 32.7232 4.66213 32.0989 4.81411V4.83105C32.955 4.95077 33.0036 5.48705 33.0189 6.25512C33.043 7.38344 33.0834 7.49496 33.2753 7.57587V7.64803H31.8272C31.6942 7.27651 31.6451 6.88018 31.6834 6.48745C31.6834 5.49525 31.5473 5.28479 31.1236 5.28479H30.6114V7.64639H29.2666V1.93372ZM30.6114 4.34233H31.0274C31.124 4.34714 31.2204 4.33069 31.3099 4.29415C31.3994 4.2576 31.4798 4.20186 31.5453 4.13084C31.6109 4.05983 31.6601 3.97529 31.6895 3.88318C31.7188 3.79107 31.7276 3.69364 31.7151 3.59777C31.7151 3.16591 31.5555 2.87781 31.0192 2.87781H30.6114V4.34233Z" fill="white"/>
<path d="M33.8613 1.93359H37.1659V3.02146H35.2056V4.16563H37.0462V5.25405H35.2056V6.55785H37.2539V7.64626H33.8613V1.93359Z" fill="white"/>
</g>
</g>
<defs>
<clipPath id="clip0_2849_11143">
<rect width="41" height="40" fill="white"/>
</clipPath>
<clipPath id="clip1_2849_11143">
<rect width="41" height="40" fill="white"/>
</clipPath>
</defs>
</svg>
`;