import { Price, styled } from '@gcc/autobahn-components';
import InlineSVG from 'svg-inline-react';

export const StyledPrice = styled(Price)`
  & [class*='StyledPromoDescription'] {
    color: #666;
    font-size: var(--font-size-6);
    text-transform: none;
  }  
  & [class*='StyledSaving'] {
    color: #00873C;
  }
  display: flex;
`;

export const PriceAndPromoWrapper = styled.div`
 display: flex;
 flex-direction: column;
 align-items: baseline;
 line-height: 1;
`;

export const BadgePriceSuffix = styled.div`
 display: flex;
 flex-direction: row;
 align-items: center;
`;

export const CurrencyAndPrice = styled.div`
 flex-direction: row;
 font-size: var(--font-size-1);
 color: var(--black);
 font-weight: 600;
`;

export const Currency = styled.span`
 font-size: 0.5em;
 vertical-align: super;
`;

export const Dollars = styled.span`
`;

export const Cents = styled.span`
 font-size: 0.5em;
 vertical-align: super;
`;

export const Suffix = styled.div`
 font-size: var(--font-size-7);
 align-self: end;
`;

export const PromoMessage = styled.div`
 font-size: var(--font-size-6);
 color: #666;
 font-weight: 700;
 padding-top: var(--spacing-extra-small);
`;

export const BMSMIcon = styled(InlineSVG)`
margin-right: 5px;
`;
